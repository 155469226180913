const foundation = require('foundation-sites');
const $ = jQuery;
const readmore = require('readmore-js');
$(document).foundation();
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
if (supportsTouch) {
  jQuery('body').addClass('touch-device');
}

import './plugins';


var $imageSlider = $('.news-slider').slick({
  autoplay: false,
  speed: 900,
  loop: false,
  pauseOnHover: true,
  arrows: true,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  responsiveFirst: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
      }
    },
  ]

});